import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  Textarea,
  Checkbox,
  Box,
  useToast,
  HStack,
  Heading,
  Image,
  Stack,
  InputGroup,
  InputLeftElement,
  ModalFooter,
} from "@chakra-ui/react";
import { MdDescription } from "react-icons/md";
import { useOwnStore } from "../../../zustand";
import axios from "axios";

const CreatePackageModal = ({
  isOpen,
  setIsOpen,
  fetchPackages,
  fetchTests,
}) => {
  const { tests } = useOwnStore();
  const [packageData, setPackageData] = useState({
    name: "",
    description: "",
    price: "",
    tests: [],
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPackageData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTestChange = (testId, isChecked) => {
    setPackageData((prevData) => ({
      ...prevData,
      tests: isChecked
        ? [...prevData?.tests, testId]
        : prevData?.tests?.filter((test) => test !== testId),
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // console.log("calling package data before creating", packageData);
      const res = axios.post(
        `${process.env.REACT_APP_SERVER}/createBasicPackage`,
        packageData
      );

      fetchPackages();
      setIsOpen(false);

      toast({
        title: "Basic package created successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error while creating package:");
      toast({
        title: "Error while creating basic package",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTests();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} size={"4xl"}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalCloseButton color={"white"} />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading color={"white"}>Create New Health Package</Heading>
              <Stack
                sx={{
                  display: "flex",
                  flexDir: "column",
                  gap: "3",
                  width: "100%",
                }}
              >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <MdDescription />
                  </InputLeftElement>
                  <Input
                    placeholder="Package Name"
                    name="name"
                    value={packageData?.name}
                    onChange={handleChange}
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    ₹
                  </InputLeftElement>
                  <Input
                    placeholder="Price"
                    type="number"
                    name="price"
                    value={packageData?.price}
                    onChange={handleChange}
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                </InputGroup>

                <Textarea
                  placeholder="Package Description"
                  name="description"
                  value={packageData?.description}
                  onChange={handleChange}
                  color={"white"}
                  focusBorderColor="teal.500"
                />
              </Stack>
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter
          display={"flex"}
          flexDir={"column"}
          gap={"5"}
          alignItems={"flex-start"}
        >
          <Heading color={"white"}>Available Tests</Heading>
          <Box
            gap={"2"}
            display={"flex"}
            flexDirection={"row"}
            overflowWrap={"break-word"}
            flexWrap={"wrap"}
          >
            {tests?.map((test) => (
              <Checkbox
                color={"white"}
                margin={2}
                key={test?._id}
                isChecked={packageData?.tests?.includes(test?._id)}
                onChange={(e) => handleTestChange(test?._id, e.target.checked)}
              >
                {test?.name}
              </Checkbox>
            ))}
          </Box>
          <Button
            width={"30%"}
            isLoading={loading}
            variant={"solid"}
            colorScheme="teal"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreatePackageModal;
