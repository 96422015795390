import {
  Button,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";

export const BankDetailsModal = ({ labId, open, setOpen, handleApproved }) => {
  const toast = useToast();
  const [bankData, setBankData] = useState({
    accountHolder: "",
    accountNumber: "",
    branchName: "",
    bankName: "",
    labId: labId,
  });
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (e) => {
    setBankData({ ...bankData, [e.target?.name]: e.target?.value });
  };

  const submitHanlder = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/bankDetails/add`,
        bankData
      );
    //   console.log("calling add bank res", res);
      handleApproved();
      setOpen(false);
      toast({
        title: "bank details added successfully",
        status: "sucess",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "error while adding bank details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error fetching bank details:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size={"4xl"}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalCloseButton color={"white"} />
        <ModalBody h={"80%"} overflowY={"scroll"}>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Image src={"/assets/bank/3.png"} width={"8xl"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading color={"white"}>Add Lab Bank Details</Heading>
              <Stack
                sx={{
                  display: "flex",
                  flexDir: "column",
                  gap: "3",
                  width: "100%",
                }}
              >
                <Input
                  type="text"
                  placeholder="Account Holder Name"
                  name="accountHolder"
                  value={bankData?.accountHolder}
                  focusBorderColor="teal.500"
                  color={"white"}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Input
                  type="text"
                  placeholder="Account Number"
                  name="accountNumber"
                  value={bankData?.accountNumber}
                  focusBorderColor="teal.500"
                  color={"white"}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Input
                  type="text"
                  placeholder="Bank Name"
                  name="bankName"
                  value={bankData?.bankName}
                  focusBorderColor="teal.500"
                  color={"white"}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Input
                  type="text"
                  placeholder="Branch Name"
                  name="branchName"
                  value={bankData?.branchName}
                  focusBorderColor="teal.500"
                  color={"white"}
                  onChange={(e) => onChangeHandler(e)}
                />
                <Button
                  width={"full"}
                  variant={"solid"}
                  colorScheme="teal"
                  onClick={submitHanlder}
                  isLoading={loading}
                >
                  Submit Details
                </Button>
              </Stack>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
