import {
  Box,
  Button,
  HStack,
  Heading,
  Input,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useOwnStore } from "../../zustand";
import axios from "axios";
import ProfileEditModal from "./EditModal";
import DeleteUserModal from "./DeleteModal";

export const Users = () => {
  const { users, setUsers } = useOwnStore();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toast = useToast();

  const handleSuspention = async (user) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/suspendUser/${user?._id}`
      );

      setUser(res?.data.user);
      fetchUsers();
      toast({
        title: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("error while suspending user", error);
      toast({
        title: "error while updating suspention",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const EditHandler = (user) => {
    setUser(user);
    setOpen(true);
  };

  const deleteHandler = (user) => {
    setUser(user);
    setDeleteModal(true);
  };
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/allusers`);
    //   console.log("calling all users responses data", res?.data.users);
      setUsers(res?.data.users);
    } catch (error) {
      toast({
        title: "error while fetching all users",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    //   console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    console.log("fetching all users in the app");
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="teal.500"
            size="xl"
          />
        </Box>
      ) : (
        <VStack
          flex={1}
          sx={{
            display: "flex",
            maxWidth: "100%",
            height: "85vh",
            flexDir: "column",
            px: ["0", "0", "0", "5"],
            py: 5,
            gap: 5,
            overflowY: "scroll",
            marginLeft: ["0px", "0px", "260px", "260px"],
            zIndex: "1000",
            alignItems: "center",
            pb: "100px",
          }}
        >
          <Heading fontSize={"2xl"} color={"white"}>
            Users List
          </Heading>
          <TableContainer width={"100%"}>
            <Table
              variant="simple"
              width="100%"
              p="0"
              css={{ tableLayout: "fixed" }}
            >
              <Thead width="100%" bg={"teal"} boxShadow={"2xl"}>
                <Tr width="100%">
                  <Th width="25%" color={"white"}>
                    Name
                  </Th>
                  <Th width="25%" color={"white"}>
                    Phone
                  </Th>
                  <Th width="25%" color={"white"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {users?.map((u, index) => (
                  <Tr
                    key={index}
                    width="100%"
                    transition={"ease-in 0.2s"}
                    cursor={"default"}
                    _hover={{
                      bg: "gray.800",
                    }}
                  >
                    <Td width="25%">
                      <Text
                        display={"flex"}
                        color={"white"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        flexWrap={"wrap"}
                        overflowWrap={"break-word"}
                        whiteSpace={"normal"}
                        maxWidth={"100%"}
                      >
                        {u?.firstName
                          ? `${u?.firstName}  ${u?.lastName}`
                          : "user"}
                      </Text>
                    </Td>
                    <Td width="25%">
                      <Text color={"white"}>{u?.phone}</Text>
                    </Td>
                    <Td width="25%">
                      <Stack
                        justifyContent={"space-between"}
                        width={"100%"}
                        flexDir={"row"}
                      >
                        <Button
                          colorScheme="teal"
                          onClick={() => EditHandler(u)}
                        >
                          Edit
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => deleteHandler(u)}
                        >
                          Delete
                        </Button>
                        <Switch
                          size="lg"
                          colorScheme="teal"
                          onChange={() => handleSuspention(u)}
                          isChecked={u?.isSuspended}
                        />
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <ProfileEditModal
            user={user}
            setUser={setUser}
            setOpen={setOpen}
            open={open}
            fetchUsers={fetchUsers}
          />
          <DeleteUserModal
            open={deleteModal}
            setOpen={setDeleteModal}
            fetchUsers={fetchUsers}
            user={user}
          />
        </VStack>
      )}
    </>
  );
};
