import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  HStack,
  Button,
  Avatar,
  Stack,
  FormControl,
  Textarea,
  Box,
  VStack,
  useToast,
  Text,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  ModalHeader,
} from "@chakra-ui/react";
import axios from "axios";

const DeleteUserModal = ({ user, open, setOpen, fetchUsers }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id) => {
    try {
      setLoading(true);
      const res = await axios.delete(
        `${process.env.REACT_APP_SERVER}/deleteUser/${id}`
      );
    //   console.log("calling delete user responses data", res);
      fetchUsers();
      setOpen(false);
      toast({
        title: "user deleted successfully",
        status: "success",
        duration: "5000",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "error while deleting a user",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    //   console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelDeleteHandler = () => {
    setOpen(false);
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size={"2xl"}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalCloseButton color={"white"} />
        <ModalHeader color={"white"} textAlign={"center"}>
          Are you sure you want to delete this user?
        </ModalHeader>
        <ModalBody h={"80%"} overflowY={"scroll"}>
          <HStack
            width={"100%"}
            padding={5}
            justifyContent={"center"}
            gap={"5"}
          >
            <Button
              colorScheme="red"
              onClick={() => deleteHandler(user?._id)}
              isLoading={loading}
              loadingText={"deleting"}
            >
              Yes Delete
            </Button>
            <Button colorScheme="blue" onClick={cancelDeleteHandler}>
              Cancel
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteUserModal;
