import {
  Box,
  Button,
  Checkbox,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useOwnStore } from "../../../zustand";
import { useEffect, useState } from "react";
import axios from "axios";

const EditPackageModal = ({
  isOpen,
  onClose,
  pack,
  fetchPackages,
  fetchTests,
}) => {
  const { tests } = useOwnStore();
  const [packageData, setPackageData] = useState({
    name: pack?.name,
    description: pack?.description,
    price: pack?.price,
    tests: pack?.tests,
  });

  // console.log("calling package data before creating", packageData);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPackageData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTestChange = (testId, isChecked) => {
    setPackageData((prevData) => ({
      ...prevData,
      tests: isChecked
        ? [...prevData.tests, testId]
        : prevData.tests.filter((test) => test !== testId),
    }));
  };

  const handleSubmit = async (id) => {
    setLoading(true);
    try {
      // console.log("calling package data before updating", packageData);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateBasicPackage/${id}`,
        packageData
      );
      // console.log("calling update package res in packageCard", res);
      setPackageData(res.data.updatedPackage);
      fetchPackages();
      onClose();

      toast({
        title: "Basic package updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error while updating basic package");
      toast({
        title: "Error while updating package",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTests();
    setPackageData(pack);
    // console.log("calling tests", tests);
    // console.log("calling packagedata", packageData?.tests);
  }, [pack]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalCloseButton color={"white"} />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading color={"white"}>Update Health Package</Heading>
              <Stack
                sx={{
                  display: "flex",
                  flexDir: "column",
                  gap: "3",
                  width: "100%",
                }}
              >
                <Input
                  placeholder="Package Name"
                  name="name"
                  value={packageData?.name}
                  onChange={handleChange}
                  color={"white"}
                  focusBorderColor="teal.500"
                />
                <Textarea
                  placeholder="Package Description"
                  name="description"
                  value={packageData?.description}
                  onChange={handleChange}
                  color={"white"}
                  focusBorderColor="teal.500"
                />
                <Input
                  placeholder="Price"
                  type="number"
                  name="price"
                  value={packageData?.price}
                  onChange={handleChange}
                  color={"white"}
                  focusBorderColor="teal.500"
                />
              </Stack>
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter
          display={"flex"}
          flexDir={"column"}
          gap={"5"}
          alignItems={"flex-start"}
        >
          <Heading color={"white"}>Available Tests</Heading>
          <Box
            gap={"2"}
            display={"flex"}
            flexDirection={"row"}
            overflowWrap={"break-word"}
            flexWrap={"wrap"}
          >
            {tests?.map((test) => (
              <Checkbox
                color={"white"}
                margin={2}
                key={test?._id}
                isChecked={packageData?.tests?.includes(test?._id)}
                onChange={(e) => handleTestChange(test?._id, e.target.checked)}
              >
                {test?.name}
              </Checkbox>
            ))}
          </Box>
          <Button
            width={"30%"}
            isLoading={loading}
            variant={"solid"}
            colorScheme="teal"
            onClick={() => handleSubmit(pack?._id)}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPackageModal;
