import { Box, Button, Flex, Stack, Td, Text, Tr } from "@chakra-ui/react";

export const PackageList = ({
  pack,
  setPackageData,
  setEditOpen,
  setDeleteOpen,
}) => {
  const EditHandler = () => {
    setPackageData(pack);
    setEditOpen(true);
  };

  const deleteHandler = () => {
    setPackageData(pack);
    setDeleteOpen(true);
  };

  return (
    <>
      <Tr
        width="100%"
        transition={"ease-in 0.2s"}
        cursor={"default"}
        _hover={{
          bg: "gray.800",
        }}
      >
        <Td width="25%">
          <Text
            display={"flex"}
            color={"white"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            overflowWrap={"break-word"}
            whiteSpace={"normal"}
            maxWidth={"100%"}
          >
            <Flex gap={3}>
              <Box sx={{}}>
                <Text color={"white"}>{pack?.name}</Text>
                <Text color={"gray.400"}>
                  {pack?.description.slice(0, 30)}...
                </Text>
              </Box>
            </Flex>
          </Text>
        </Td>
        <Td width="25%">
          <Text color={"white"}>{pack?.price}</Text>
        </Td>
        <Td width="25%">
          <Stack
            justifyContent={"space-between"}
            width={"100%"}
            flexDir={"row"}
            gap={"10"}
          >
            <Button colorScheme="teal" onClick={EditHandler} width={"100%"}>
              Edit
            </Button>
            <Button colorScheme="red" onClick={deleteHandler} width={"100%"}>
              Delete
            </Button>
          </Stack>
        </Td>
      </Tr>
    </>
  );
};
