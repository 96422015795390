import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useOwnStore } from "../../zustand";
import axios from "axios";
import { EditLabModal } from "./EditLabModal";
import DeletelabModal from "./DeleteLabModal";

export const Labs = () => {
  const { labs, setLabs } = useOwnStore();
  const [lab, setlab] = useState({});
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toast = useToast();

  const handleSuspention = async (lab) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/suspention/${lab?._id}`
      );

      setlab(res?.data.lab);
      fetchlabs();
      toast({
        title: res?.data?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("error while suspending lab", error);
      toast({
        title: "error while updating suspention",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const EditHandler = (lab) => {
    setlab(lab);
    setOpen(true);
  };

  const deleteHandler = (lab) => {
    setlab(lab);
    setDeleteModal(true);
  };
  const fetchlabs = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/getLabs`);
    //   console.log("calling all labs data", res.data?.labs);
      setLabs(res?.data?.labs);
    } catch (error) {
      toast({
        title: "error while fetching all labs",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    //   console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchlabs();
    // console.log("fetching all labs in the labs list");
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="teal.500"
            size="xl"
          />
        </Box>
      ) : (
        <VStack
          flex={1}
          sx={{
            display: "flex",
            maxWidth: "100%",
            height: "85vh",
            flexDir: "column",
            px: ["0", "0", "0", "5"],
            py: 5,
            gap: 5,
            overflowY: "scroll",
            marginLeft: ["0px", "0px", "260px", "260px"],
            zIndex: "1000",
            alignItems: "center",
            pb: "100px",
          }}
        >
          <Heading fontSize={"2xl"} color={"white"}>
            Labs List
          </Heading>
          <TableContainer width={"100%"}>
            <Table
              variant="simple"
              width="100%"
              p="0"
              css={{ tableLayout: "fixed" }}
            >
              <Thead width="100%" bg={"teal"} boxShadow={"2xl"}>
                <Tr width="100%">
                  <Th width="25%" color={"white"}>
                    Lab Name
                  </Th>
                  <Th width="25%" color={"white"}>
                    Email
                  </Th>
                  <Th width="25%" color={"white"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {labs?.map((lab, index) => (
                  <Tr
                    key={index}
                    width="100%"
                    transition={"ease-in 0.2s"}
                    cursor={"default"}
                    _hover={{
                      bg: "gray.800",
                    }}
                  >
                    <Td width="25%">
                      <Text
                        display={"flex"}
                        color={"white"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        flexWrap={"wrap"}
                        overflowWrap={"break-word"}
                        whiteSpace={"normal"}
                        maxWidth={"100%"}
                      >
                        <Flex gap={3}>
                          <Box sx={{}}>
                            <Text color={"white"}>
                              {lab?.labName.slice(0, 30)}...
                            </Text>
                            <Text color={"gray.400"}>{lab?.name}</Text>
                          </Box>
                        </Flex>
                      </Text>
                    </Td>
                    <Td width="25%">
                      <Text color={"white"}>{lab?.email}</Text>
                    </Td>
                    <Td width="25%">
                      <Stack
                        justifyContent={"space-between"}
                        width={"100%"}
                        flexDir={"row"}
                      >
                        <Button
                          colorScheme="teal"
                          onClick={() => EditHandler(lab)}
                        >
                          Edit
                        </Button>

                        <Button
                          colorScheme="red"
                          onClick={() => deleteHandler(lab)}
                        >
                          Delete
                        </Button>
                        <Switch
                          size="lg"
                          colorScheme="teal"
                          onChange={() => handleSuspention(lab)}
                          isChecked={lab?.isSuspended}
                        />
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <EditLabModal
            lab={lab}
            setlab={setlab}
            onClose={() => setOpen(!open)}
            isOpen={open}
            fetchlabs={fetchlabs}
          />
          <DeletelabModal
            open={deleteModal}
            setOpen={setDeleteModal}
            fetchlabs={fetchlabs}
            lab={lab}
          />
        </VStack>
      )}
    </>
  );
};
