/*global google */

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  HStack,
  Button,
  Textarea,
  InputGroup,
  InputRightAddon,
  Stack,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { ImLocation2 } from "react-icons/im";
import { statesList } from "../StatesList";

export const EditLabModal = ({ isOpen, onClose, lab, setlab, fetchlabs }) => {
  const [labId, setlabId] = useState();
  const [labData, setLabData] = useState("");
  const [loading, setLoading] = useState(false);

  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const [otherDetails, setOtherDetails] = useState("");
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const toast = useToast();

  const handleChange = (event) => {
    setLabData({ ...labData, [event.target.name]: event.target.value });
  };

  const handleOtherDetails = (event) => {
    setOtherDetails(event.target.value);
    setLabData({ ...labData, designation: event.target.value });
  };

  const handleDesignationChange = (event) => {
    const selectedDesignation = event.target.value;
    handleChange(event);

    if (selectedDesignation === "Other") {
      setShowOtherDetails(true);
    } else {
      setShowOtherDetails(false);
      setOtherDetails("");
      setLabData({ ...labData, designation: selectedDesignation });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (labData?.designation === "Other") {
      setLabData({ ...labData, designation: otherDetails });
    }
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateLab/${labId}`,
        labData
      );
      setlab(res?.data.updatedLab);
      setLabData(res?.data?.updatedLab);
      fetchlabs();
      onClose();
      toast({
        title: "lab details updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("error while updating lab details", error);
      toast({
        title: "error while updating lab details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setlabId(lab?._id);

    const labDetails = {
      name: lab?.name,
      labName: lab?.labName,
      description: lab?.description,
      email: lab?.email,
      phone: lab?.phone,
      country: lab?.country,
      state: lab?.state,
      district: lab?.district,
      city: lab?.city,
      pincode: lab?.pincode,
      labAddress: lab?.labAddress,
      workingDays: lab?.workingDays,
      labSize: lab?.labSize,
      labType: lab?.labType,
      landmark: lab?.landmark,
      sampleCapacity: lab?.sampleCapacity,
      openingTime: lab?.openingTime,
      closingTime: lab?.closingTime,
      doctorName: lab.doctorName,
      specialisation: lab?.specialisation,
      designation: lab?.designation,
    };

    setLabData(labDetails);
  }, [lab]);

  useEffect(() => {
    const initAutocompleteService = () => {
      setAutocompleteService(new google.maps.places.AutocompleteService());
    };

    if (isOpen) {
      if (!window.google) {
        // Google Maps API not loaded yet, initialize it
        const googleMapsScript = document.createElement("script");
        googleMapsScript.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyBB5UQNwaNzVxOTwp6okWXmx6VijFl_Kfw&libraries=places";
        googleMapsScript.async = true;
        googleMapsScript.defer = true;
        googleMapsScript.onload = initAutocompleteService;
        document.body.appendChild(googleMapsScript);
      } else {
        // Google Maps API already loaded, initialize autocomplete directly
        initAutocompleteService();
      }
    }
  }, [isOpen]);

  const fetchAddressPredictions = (input) => {
    if (!autocompleteService) return;

    // console.log("calling autocomplete", autocompleteService);

    autocompleteService.getPlacePredictions(
      { input },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
        //   console.log("calling fetch address predictions", predictions, status);
          setSuggestions(predictions);
        } else {
          console.error("Error fetching predictions:", status);
        }
      }
    );
  };

  const handleLabAddressChange = (event) => {
    const placeName = event.target.value;
    setLabData({ ...labData, labAddress: { placeName: placeName } });
    fetchAddressPredictions(placeName);
  };

  const handleSuggestionClick = (prediction) => {
    const placeName = prediction.description;
    const placeId = prediction.place_id;

    // Fetch details of the selected place using the placeId
    const placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails({ placeId: placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const { lat, lng } = place.geometry.location.toJSON();
        setLabData({
          ...labData,
          labAddress: { placeName, placeId, lat, lng },
        });
      } else {
        console.error("Error fetching place details:", status);
      }
    });
    setSuggestions([]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={"flex"} justifyContent={"center"} fontSize={"30"}>
          Edit Lab Profile
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack width={"100%"} gap={"5"}>
            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <Input
                type="text"
                name="labName"
                placeholder="Lab Name"
                value={labData?.labName ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
              />
              <Input
                type="text"
                name="name"
                value={labData?.name ?? ""}
                placeholder="Owner Name"
                onChange={handleChange}
                focusBorderColor="teal.500"
              />
            </Stack>

            <Textarea
              name="description"
              value={labData?.description ?? ""}
              onChange={handleChange}
              placeholder="Lab Description"
              focusBorderColor="teal.500"
            />
            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <Input
                type="email"
                name="email"
                value={labData?.email ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Email"
              />
              <Input
                type="tel"
                name="phone"
                value={labData?.phone ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Phone Number"
              />
              <Select
                name="labType"
                value={labData?.labType ?? ""}
                onChange={handleChange}
                placeholder="Lab Type"
                focusBorderColor="teal.500"
              >
                <option value="Pathology">Pathology</option>
                <option value="Radiology">Radiology</option>
                <option value="Both">both</option>
              </Select>
            </Stack>
            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <Input
                type="text"
                name="country"
                value={labData?.country ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Country"
              />
              <Select
                name="state"
                value={labData?.state ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Select State"
                maxHeight="50px"
                overflowY="scroll"
              >
                {statesList.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </Select>
              <Input
                type="text"
                name="district"
                value={labData?.district ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="District"
              />
            </Stack>
            <Textarea
              name="landmark"
              value={labData?.landmark ?? ""}
              onChange={handleChange}
              placeholder="Land Mark"
              focusBorderColor="teal.500"
            />
            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <Input
                type="text"
                name="city"
                value={labData?.city ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="City"
              />
              <Input
                type="number"
                name="pincode"
                value={labData?.pincode ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Pincode"
              />
              <Input
                type="number"
                name="labSize"
                value={labData?.labSize ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Lab Size in sqft"
              />
              <Input
                type="number"
                name="sampleCapacity"
                value={labData?.sampleCapacity ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Sample Capacity"
              />
            </Stack>
            <Input
              name="labAddress"
              type="text"
              value={labData?.labAddress?.placeName ?? ""}
              onChange={handleLabAddressChange}
              placeholder="Lab Address"
              focusBorderColor="teal.500"
            />
            {suggestions.length > 0 && (
              <VStack width={"100%"} gap={2} alignItems={"flex-start"}>
                {suggestions.map((prediction) => (
                  <HStack
                    key={prediction.place_id}
                    width={"100%"}
                    cursor={"pointer"}
                    onClick={() => handleSuggestionClick(prediction)}
                    justifyContent={"flex-start"}
                  >
                    <ImLocation2 color="teal" />
                    <Text flexWrap={"wrap"} overflowWrap={"wrap"}>
                      {prediction.description}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            )}

            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <InputGroup>
                <Input
                  type="text"
                  name="openingTime"
                  value={labData?.openingTime ?? ""}
                  onChange={handleChange}
                  focusBorderColor="teal.500"
                  placeholder="Opening Time"
                />
                <InputRightAddon pointerEvents={"none"}>AM</InputRightAddon>
              </InputGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="closingTime"
                  value={labData?.closingTime ?? ""}
                  onChange={handleChange}
                  focusBorderColor="teal.500"
                  placeholder="Closing Time"
                />
                <InputRightAddon>PM</InputRightAddon>
              </InputGroup>

              <Select
                name="workingDays"
                value={labData?.workingDays ?? ""}
                onChange={handleChange}
                placeholder="Working Days"
                focusBorderColor="teal.500"
              >
                <option value="All Days">All Days</option>
                <option value="Mon-Sat">Mon-Sat</option>
              </Select>
            </Stack>
            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <Input
                type="text"
                name="doctorName"
                value={labData?.doctorName ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Doctor Name"
              />
              <Input
                type="text"
                name="specialisation"
                value={labData?.specialisation ?? ""}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Specialisation"
              />

              <Select
                name="designation"
                value={labData?.designation ?? ""}
                onChange={handleDesignationChange}
                placeholder="Designation"
                focusBorderColor="teal.500"
              >
                <option value="Bio Chemist">Bio Chemist</option>
                <option value="Micro Biologist">Micro Biologist</option>
                <option value="Hametology">Hametology</option>
                <option value="Clinical Pathology">Pathology</option>
                <option value="Other">Other</option>
              </Select>
            </Stack>
            {showOtherDetails && (
              <Input
                type="text"
                name="otherDetails"
                value={otherDetails ?? ""}
                onChange={(e) => handleOtherDetails(e)}
                focusBorderColor="teal.500"
                placeholder="Enter other details"
              />
            )}
            <HStack py={"5"} justifyContent={"center"} width={"100%"}>
              <Button
                colorScheme="teal"
                type="submit"
                onClick={handleSubmit}
                width={"20%"}
                isLoading={loading}
              >
                Update Details
              </Button>
              <Button variant="solid" onClick={onClose}>
                Cancel
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
