import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  VStack,
  Heading,
  Image,
  Input,
  Stack,
  useToast,
  Textarea,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { MdDescription, MdVerifiedUser } from "react-icons/md";
import axios from "axios";

export const CreateTestModal = ({ isOpen, setIsOpen,fetchTests }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [test, setTest] = React.useState({
    name: "",
    price: "",
    description: "",
    suitableFor: "",
  });

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/createBasicTest`,
        test
      );

      console.log("calling create test response", res);

      fetchTests();
      toast({
        title: "test created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: "error while creating test",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  function onClose() {
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} sx={{}}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton color={"white"} />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading color={"white"}>Create Test</Heading>
              <Stack
                sx={{
                  display: "flex",
                  flexDir: "column",
                  gap: "3",
                  width: "100%",
                }}
              >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <MdDescription />
                  </InputLeftElement>
                  <Input
                    type="text"
                    placeholder="Enter Name"
                    color={"white"}
                    focusBorderColor="teal.500"
                    size={"lg"}
                    onChange={(e) =>
                      setTest((prevTest) => ({
                        ...prevTest,
                        name: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    ₹
                  </InputLeftElement>
                  <Input
                    type="number"
                    color={"white"}
                    focusBorderColor="teal.500"
                    placeholder="Enter Price"
                    size={"lg"}
                    onChange={(e) =>
                      setTest((prevTest) => ({
                        ...prevTest,
                        price: e.target.value,
                      }))
                    }
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <MdVerifiedUser />
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={"white"}
                    focusBorderColor="teal.500"
                    placeholder="Enter Suitable For"
                    size={"lg"}
                    onChange={(e) =>
                      setTest((prevTest) => ({
                        ...prevTest,
                        suitableFor: e.target.value,
                      }))
                    }
                  />
                </InputGroup>

                <Textarea
                  placeholder="Enter Description"
                  size={"lg"}
                  color={"white"}
                  focusBorderColor="teal.500"
                  onChange={(e) =>
                    setTest((prevTest) => ({
                      ...prevTest,
                      description: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Button
                width={"full"}
                isLoading={loading}
                variant={"solid"}
                colorScheme="teal"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
