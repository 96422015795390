import {
  Button,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";

export const EditTestModal = ({ isOpen, onClose, test, fetchTests }) => {
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const [testData, setTestData] = useState({
    name: test?.name,
    price: test?.price,
    description: test?.description,
    suitableFor: test?.suitableFor,
  });

  const handleSubmit = async (testId) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateBasicTest/${testId}`,
        testData
      );
      // console.log("calling testDat before updating ", testData);
      setTestData(res?.data?.updatedTest);
      fetchTests();
      toast({
        title: "lab test updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: "error while updating lab test",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTestData(test);
  }, [test]);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} sx={{}}>
        <ModalOverlay />
        <ModalContent bg={"#282c34"}>
          <ModalCloseButton color={"white"} />
          <ModalBody>
            <HStack alignItems={"flex-start"} spacing={5}>
              <VStack flex={1} p={5} display={["none", "flex"]}>
                <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
              </VStack>
              <VStack flex={1} p={[1, 5]} spacing={7}>
                <Heading color={"white"}>Update Test</Heading>
                <Stack
                  sx={{
                    display: "flex",
                    flexDir: "column",
                    gap: "3",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Enter Name"
                    size={"lg"}
                    color={"white"}
                    focusBorderColor="teal.500"
                    value={testData?.name}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        name: e.target.value,
                      }))
                    }
                  />
                  <Input
                    placeholder="Enter Price"
                    size={"lg"}
                    color={"white"}
                    focusBorderColor="teal.500"
                    value={testData?.price}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        price: e.target.value,
                      }))
                    }
                  />
                  <Input
                    placeholder="Enter Suitable For"
                    size={"lg"}
                    color={"white"}
                    focusBorderColor="teal.500"
                    value={testData?.suitableFor}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        suitableFor: e.target.value,
                      }))
                    }
                  />
                  <Textarea
                    placeholder="Enter Description"
                    size={"lg"}
                    color={"white"}
                    focusBorderColor="teal.500"
                    value={testData?.description}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        description: e.target.value,
                      }))
                    }
                  />
                </Stack>
                <Button
                  width={"full"}
                  isLoading={loading}
                  loadingTex={"updating"}
                  variant={"solid"}
                  colorScheme="teal"
                  onClick={() => handleSubmit(test?._id)}
                >
                  Update
                </Button>
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
