import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Spinner,
  Heading,
  VStack,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOwnStore } from "../../zustand";
import { PackageList } from "./PackageList";
import CreatePackageModal from "./CreatePackageModal";
import EditPackageModal from "./EditPackageModal";
import DeletePackageModal from "./DeletePackageModal";

export const HealthPackage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { packages, setTests, setPackages } = useOwnStore();
  const [packageData, setPackageData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchPackages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/getPackages`
      );
      const packages = response?.data?.packages;
    //   console.log("calling basic test response", response);
      setPackages(packages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/getTests`
      );
      const fetchTests = response?.data?.tests;
    //   console.log("calling basic test response", response);
      //   const fetchLabTests = await getLabTests(lab?._id);
      const newData = [...fetchTests];
      setTests(newData?.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <>
      <VStack
        flex={1}
        sx={{
          display: "flex",
          maxWidth: "100%",
          height: "85vh",
          flexDir: "column",
          px: ["0", "0", "0", "5"],
          py: 5,
          gap: 5,
          overflowY: "scroll",
          marginLeft: ["0px", "0px", "260px", "260px"],
          zIndex: "1000",
          alignItems: "center",
          pb: "100px",
        }}
      >
        <Button
          variant={"solid"}
          colorScheme="teal"
          width={"64"}
          boxShadow={"0px 0px 5px 2px rgba(0,179,137,1)"}
          onClick={() => setIsOpen(true)}
          px={3}
          border={"1px solid white"}
          py={5}
        >
          + Create new package
        </Button>
        <Heading fontSize={"2xl"} color={"white"}>
          All Health Packages
        </Heading>
        <TableContainer width={"100%"}>
          <Table
            variant="simple"
            width="100%"
            p="0"
            css={{ tableLayout: "fixed" }}
          >
            <Thead width="100%" bg={"teal"} boxShadow={"2xl"}>
              <Tr width="100%">
                <Th width="25%" color={"white"}>
                  Name
                </Th>
                <Th width="25%" color={"white"}>
                  Price
                </Th>
                <Th width="25%" color={"white"}>
                  Action
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "60vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner size={"lg"} />
                </Box>
              ) : (
                <>
                  {packages?.map((e, i) => {
                    return (
                      <PackageList
                        setPackageData={setPackageData}
                        pack={e}
                        setEditOpen={setEditOpen}
                        setDeleteOpen={setDeleteOpen}
                      />
                    );
                  })}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
      <CreatePackageModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fetchPackages={fetchPackages}
        fetchTests={fetchTests}
      />
      <EditPackageModal
        isOpen={editOpen}
        onClose={() => setEditOpen(!editOpen)}
        pack={packageData}
        fetchPackages={fetchPackages}
        fetchTests={fetchTests}
      />
      <DeletePackageModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        fetchPackages={fetchPackages}
        pack={packageData}
      />
    </>
  );
};
