import { Heading, VStack } from "@chakra-ui/react";

export const PriceController = () => {
  return (
    <VStack
      flex={1}
      sx={{
        display: "flex",
        maxWidth: "100%",
        height: "85vh",
        flexDir: "column",
        px: ["0", "0", "0", "5"],
        py: 5,
        gap: 5,
        overflowY: "scroll",
        marginLeft: ["0px", "0px", "260px", "260px"],
        zIndex: "1000",
        alignItems: "center",
        justifyContent: "center",
        pb: "100px",
      }}
    >
      <Heading color={"white"} fontSize={"2xl"}>
        Coming soon
      </Heading>
    </VStack>
  );
};
