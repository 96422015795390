import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  useToast,
  HStack,
  Button,
} from "@chakra-ui/react";
import axios from "axios";

const DeletePackageModal = ({ pack, open, setOpen, fetchPackages }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const deleteHandler = async (id) => {
    try {
      setLoading(true);
      const res = await axios.delete(
        `${process.env.REACT_APP_SERVER}/deleteBasicPackage/${id}`
      );

      fetchPackages();
      setOpen(false);
      toast({
        title: "Package deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error while deleting a basic package",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const cancelDeleteHandler = () => {
    setOpen(false);
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size={"2xl"}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalCloseButton color={"white"} />
        <ModalHeader color={"white"} textAlign={"center"}>
          Are you sure you want to delete this package?
        </ModalHeader>
        <ModalBody h={"80%"} overflowY={"scroll"}>
          <HStack
            width={"100%"}
            padding={5}
            justifyContent={"center"}
            gap={"5"}
          >
            <Button
              colorScheme="red"
              onClick={() => deleteHandler(pack?._id)}
              isLoading={loading}
              loadingText={"deleting"}
            >
              Yes Delete
            </Button>
            <Button colorScheme="blue" onClick={cancelDeleteHandler}>
              Cancel
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeletePackageModal;
