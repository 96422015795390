import { create } from "zustand";

export const useOwnStore = create((set) => ({
  labs: [],
  users: [],
  tests: [],
  packages: [],
  setTests: (data) => set((state) => ({ tests: data })),
  setPackages: (data) => set((state) => ({ packages: data })),
  setUsers: (data) => set((state) => ({ users: data })),
  setLabs: (data) => set((state) => ({ labs: data })),
}));
