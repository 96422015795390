import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  HStack,
  Button,
  Avatar,
  Stack,
  FormControl,
  Textarea,
  Box,
  VStack,
  useToast,
  Text,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import axios from "axios";

const ProfileEditModal = ({ user, setUser, open, setOpen, fetchUsers }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(user);

  const getFormattedData = (dateString) => {
    const dob = new Date(dateString);
    // console.log("calling date of birth string", dateString);

    const formattedDOB = dob?.toISOString()?.split("T")[0];

    return formattedDOB;
  };

  const handleFormChange = (event) => {
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [event?.target?.name]: event?.target?.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER}/user/${user?._id}`,
        profileData
      );

      fetchUsers();
      setOpen(false);
      setUser(response?.data?.user);
      setProfileData(response.data.user);
    //   console.log("calling update user response", response);
      toast({
        title: "User details updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error while updating user",
        status: "error",
        isClosable: true,
        duration: 5000,
      });
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProfileData(user);
  }, [user]);

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size={"6xl"}>
      <ModalOverlay />
      <ModalContent bg={"#282c34"}>
        <ModalCloseButton color={"white"} />
        <ModalBody h={"80%"} overflowY={"scroll"}>
          <Box width={"100%"}>
            <FormControl>
              <Stack spacing={4} width={"100%"} p={5}>
                <Grid
                  width={"100%"}
                  gap={"4"}
                  templateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr"]}
                >
                  <Input
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={profileData?.firstName ?? ""}
                    onChange={handleFormChange}
                    placeholder="First Name"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                  <Input
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={profileData?.lastName ?? ""}
                    onChange={handleFormChange}
                    placeholder="Last Name"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                </Grid>
                <Grid
                  width={"100%"}
                  gap={"4"}
                  templateColumns={["1fr", "1fr fr", "1fr 1fr", "1fr 1fr 1fr"]}
                >
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    value={profileData?.email ?? ""}
                    onChange={handleFormChange}
                    placeholder="Your Email"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                  <HStack width={"100%"}>
                    <Select
                      name="gender"
                      value={profileData?.gender ?? ""}
                      onChange={handleFormChange}
                      placeholder="Gender"
                      color={"white"}
                      focusBorderColor="teal.500"
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </Select>
                  </HStack>
                  <Input
                    width={"100%"}
                    id="dateOfBirth"
                    type="date"
                    name="dateOfBirth"
                    value={
                      profileData?.dateOfBirth
                        ? getFormattedData(profileData?.dateOfBirth)
                        : ""
                    }
                    onChange={handleFormChange}
                    placeholder="Date of Birth"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                </Grid>
                <Grid
                  width={"100%"}
                  gap={"4"}
                  templateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]}
                >
                  <Input
                    id="country"
                    type="text"
                    name="country"
                    value={profileData?.country ?? ""}
                    onChange={handleFormChange}
                    placeholder="Country"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                  <Input
                    id="state"
                    type="text"
                    name="state"
                    value={profileData?.state ?? ""}
                    onChange={handleFormChange}
                    placeholder="State"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                  <Input
                    id="city"
                    type="text"
                    name="city"
                    value={profileData?.city ?? ""}
                    onChange={handleFormChange}
                    placeholder="City"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                </Grid>
                <Grid
                  width={"100%"}
                  gap={"4"}
                  templateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]}
                >
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    value={profileData?.password ?? ""}
                    onChange={handleFormChange}
                    placeholder="Password"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                  <Input
                    id="number"
                    type="number"
                    name="pincode"
                    value={profileData?.pincode ?? ""}
                    onChange={handleFormChange}
                    placeholder="Pincode"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                  <Input
                    id="phone"
                    type="number"
                    name="phone"
                    value={profileData?.phone ?? ""}
                    onChange={handleFormChange}
                    placeholder="Phone"
                    color={"white"}
                    focusBorderColor="teal.500"
                  />
                </Grid>
                <Textarea
                  id="address"
                  name="address"
                  value={profileData?.address ?? ""}
                  onChange={handleFormChange}
                  placeholder="Enter your address here"
                  rows={4}
                  color={"white"}
                  focusBorderColor="teal.500"
                />
                <HStack pb={"16"}>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    variant={"solid"}
                    colorScheme="teal"
                    isLoading={loading}
                    color={"white"}
                    focusBorderColor="teal.500"
                  >
                    Update Details
                  </Button>
                </HStack>
              </Stack>
            </FormControl>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfileEditModal;
